<!-- System: STA
    Purpose: Single member create a card in create
            and update project modal for user
            selection and de-selection.
-->
<template>
  <v-container>
    <v-row class="white float-left row m-0 memberClass mt-2">
      <!-- Member Name-->
      <v-col
        cols="12"
        :class="{ green: is_selected }"
        class="float-left text-center p-2 pt-2"
      >
        <p :title="member.name" class="empNameLong text-capitalize">
          {{ employee_name(member) }}
        </p>
      </v-col>
      <v-row class="w-100 float-left p-2 single-row">
        <!-- Member Image-->
        <v-col cols="10" class="mx-auto" style="height: 150px">
          <img
            :src="url() + 'storage/' + member.image"
            @error="
              $event.target.src = require('@/assets/images/no-member.svg')
            "
            alt="Thumbnail"
            class="member-image m-auto d-block"
            fluid
            thumbnail
          />
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { baseURL } from "@/assets/js/urls";

export default {
  name: "SingleMember",
  props: ["member", "selected_members"],
  data() {
    return {
      single_class_name: "",
    };
  },
  computed: {
    ...mapState("custom", ["employer_employees"]),
    is_selected() {
      return !!this.selected_members.find(
        (member) => member.id === this.member.id
      );
    },
  },
  methods: {
    /**
     * This Function returns the employee
     * name in reduced form
     */
    employee_name(member) {
      if (/\s/.test(member.name)) {
        return member.name
          ? member.name.length > 12
            ? member.name.match(
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g
              ) !== null
              ? member.name
              : member.name
                  .match(/\b(\w)/g)
                  .join(" ")
                  .toUpperCase()
                  .slice(0, -1)
                  .concat(" " + member.name.split(" ").slice(-1))
            : member.name
          : "(No name set yet)";
      } else {
        if (member.name.length > 15) {
          if (member.name.match(/^\S+$/g)) {
            let concatString = ".....";
            return member.name.slice(0, 10).concat(concatString);
          }
        }
        return member.name ? member.name : "(No name set yet)";
      }
    },
    /**
     * This Function returns the base url
     */
    url() {
      return baseURL.API_URL;
    },
  },
};
</script>

<style scoped>
.member-image {
  height: 100px;
  width: 110px;
}

.empNameLong {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  font-size: 14px;
}

.memberClass {
  height: 155px;
  width: 143px;
  box-shadow: inset 0 1px 3px 0 rgba(147, 147, 147, 0.17),
    0 0 10px 0 rgba(214, 214, 214, 0.47);
  border-radius: 3px;
}

@media (max-width: 430px) {
  .memberClass {
    width: 130px !important;
  }
}

@media (max-width: 320px) {
  .memberClass {
    width: 143px !important;
  }
}

.img-thumbnail {
  padding: unset !important;
  background-color: unset !important;
  border: unset !important;
  border-radius: 50%;
  width: 86px;
  height: 84px;
}
</style>
